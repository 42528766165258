import {
    move,
    rotateLeft,
    rotateRight,
    isGameSolvable,
    isPositionValid,
} from "./moves.js";

export const interpreter = {
    "move": (context, condition) => {
        if (!condition || context.puzzle.board[context.robot.y][context.robot.x].toLowerCase() === condition) {
            move(context.robot);
        }
        context.pc[1]++;
    },
    "turnRight": (context, condition) => {
        if (!condition || context.puzzle.board[context.robot.y][context.robot.x].toLowerCase() === condition) {
            rotateRight(context.robot);
        }
        context.pc[1]++;
    },
    "turnLeft": (context, condition) => {
        if (!condition || context.puzzle.board[context.robot.y][context.robot.x].toLowerCase() === condition) {
            rotateLeft(context.robot);
        }
        context.pc[1]++;
    },
    "jumpTo": (context, functionNumber, condition) => {
        if (!condition || context.puzzle.board[context.robot.y][context.robot.x].toLowerCase() === condition) {
            context.stack.push(context.pc);
            context.pc = [functionNumber-1, 0];
        } else {
            context.pc[1]++;
        }
    },
};

export function handleStars(context){
    if (context.stars.some(s => s.x === context.robot.x && s.y === context.robot.y)) {
        context.stars = context.stars.filter(s => s.x !== context.robot.x || s.y !== context.robot.y);
    }
}

export function step(context) {
    handleStars(context);
    if (context.stars.length === 0 || !isPositionValid(context.puzzle, context.robot) || context.program[context.pc[0]].length <= context.pc[1]) {
        return false;
    }

    const [functionName, ...args] = context.program[context.pc[0]][context.pc[1]];
    interpreter[functionName](context, ...args);

    if ((context.program[context.pc[0]].length === context.pc[1]) && context.stack.length > 0) {
        context.pc = context.stack.pop();
        context.pc[1]++;
    }

    return true;
}

const KEYWORDS = ["move", "turnLeft", "turnRight", "jumpTo"];
const CONDITIONS = ["r", "g", "b"];

export function checkProgram(program, addError, addWarning, limitations) {
    let newline = false;
    let fn = 1;

    // Check if the program is empty
    if (program.replace(/\s/g, "").replace(/\n/g, "").length === 0) {
        addError("The program is empty");
        return;
    }

    // Count the number of functions
    const functions = program.replace(/(\r\n|\r|\n){2,}/g, "$1\n").split("\n\n");
    const actualLimitations = [];
    for (let i = 0; i < functions.length; i++) {
        actualLimitations.push(functions[i].split("\n").length);
    }
    const functionCount = functions.length;

    program.split("\n").forEach((instruction, line) => {
        instruction = instruction.trim();

        if (instruction.trim() === "") {
            // If there are multiple empty lines, warn the user
            if (newline) {
                addWarning(`Extra linebreak found on line ${line + 1}`);
            } else {
                fn++;
            }
            newline = true;
        } else {
            newline = false;

            const [keyword, ...args] = instruction.split(" ");

            // If the keyword is not a valid keyword, throw an error
            if (KEYWORDS.indexOf(keyword) === -1) {
                addError(`Unknown instruction "${keyword}" in fn. ${fn}, line ${line + 1}, word 1`);
            }

            // If there are arguments
            if (args.length > 0) {

                let argumentsCount = 1;

                if (keyword === "jumpTo") {
                    argumentsCount++;

                    // If the function number is not a number, throw an error
                    if (isNaN(args[0]) || parseInt(args[0]) < 1 || parseInt(args[0]).toString().length !== args[0].length) {
                        addError(`Invalid value for function number "${args[0]}" in fn. ${fn}, line ${line + 1}, word 2`);
                    }

                    // If there is no function with the given number, throw an error
                    if (parseInt(args[0]) > functionCount) {
                        addError(`Function number "${args[0]}" is too high in fn. ${fn}, line ${line + 1}, word 2 (there are only ${functionCount} function${functionCount > 1 ? "s": ""})`);
                    }
                }

                // If the condition is not a valid condition, throw an error
                if (args.length >= argumentsCount && CONDITIONS.indexOf(args[argumentsCount - 1]) === -1) {
                    addError(`Invalid condition "${args[argumentsCount - 1]}" in fn. ${fn}, line ${line + 1}, word ${argumentsCount+1}`);
                }

                // If there are too much arguments, throw an error
                if (args.length > argumentsCount) {
                    addError(`Too many arguments in fn. ${fn}, line ${line + 1}, after word ${argumentsCount+1}`);
                }
            } else {

                // If the jumpTo instruction's syntax is wrong, throw an error
                if (keyword === "jumpTo") {
                    addError(`Missing function number after "jumpTo" in fn. ${fn}, line ${line + 1}`);
                }
            }
        }
    });

    // If the program is too long, throw a warning
    if (actualLimitations.length > limitations.length) {
        addWarning(`The program is too long (it must be resolved in maximum ${limitations.length} functions)`);
    }

    // If a function contains too many instructions, throw a warning
    for (let i = 0; i < Math.min(actualLimitations.length, limitations.length); i++) {
        if (actualLimitations[i] > limitations[i]) {
            addWarning(`Function ${i + 1} is too long (it must be composed of maximum ${limitations[i]} lines)`);
        }
    }
}

export function parseProgram(program) {
    return program
        .trim()
        .replace(/(\r\n|\r|\n){2,}/g, "$1\n")
        .split("\n\n")
        .map(f => f.split("\n")
            .map(line => line.split(" "))
            .map(x => Array.isArray(x) ? x : [x])
        );
}

export function run(context, refreshFunction, boardId) {
    // If the game is not solvable, throw an error
    if (!isGameSolvable(context)) {
        throw new Error("The map is not solvable");
    }

    // Create a new interpreter and check that the game runs correctly
    while (step(context)) {
        refreshFunction(context, boardId);
    }
}