import { clearCallStack, addCallStack, setActiveInstruction } from "./editor.js";

/**
 * Toggle viewer fullscreen mode
 */
function toggleFullScreen() {
    if (document.fullscreenElement) {
        document.exitFullscreen();
    } else {
        const viewer = document.querySelector("#viewer");
        if (viewer.requestFullscreen) {
            viewer.requestFullscreen();
        }
    }
}

// Chars used to display the elements on the board
const icons = {
    robotEast: { string: "\uEE00", color: "pink" },
    robotNorth: { string: "\uEE03", color: "pink" },
    robotWest: { string: "\uEE02", color: "pink" },
    robotSouth: { string: "\uEE01", color: "pink" },
    star: { string: "\uEE04", color: "yellow" },
    disk: { string: "\uEE05", color: "red" },
    box: { string: "\uEE06", color: "red" },
    flash: { string: "\uEE07", color: "red" },
    heart: { string: "\uEE08", color: "red" },
    heartBroken: { string: "\uEE09", color: "red" },
    lock: { string: "\uEE0A", color: "red" },
    lockOpen: { string: "\uEE0B", color: "red" },
    key: { string: "\uEE0C", color: "red" },
    droplet: { string: "\uEE0D", color: "red" },
    rocket: { string: "\uEE0E", color: "red" },
    empty: { string: "\uEE0F", color: "red" },
};

/**
 * Add a star to a board cell
 * @param {HTMLTableCellElement} aCell 
 */
function addStar(aCell) {
    aCell.innerText = icons.star.string;
    aCell.style.color = icons.star.color;
}

/**
 * Fill the board with a given puzzle by its id
 * @param {string} boardElementId the id of the board element
 * @param {JSON} puzzle the puzzle to fill the board with
 */
function fillBoard(boardElementId, puzzle) {
    const tbody = document.getElementById(boardElementId);
    tbody.innerHTML = "";
    puzzle.board.forEach((aLine) => {
        const newRow = tbody.insertRow();
        aLine.split("").forEach((aChar) => {
            const newCell = newRow.insertCell();
            switch (aChar) {
            case "r":
                newCell.className += " red_tile";
                break;
            case "g":
                newCell.className += " green_tile";
                break;
            case "b":
                newCell.className += " blue_tile";
                break;
            case "R":
                newCell.className += " red_tile";
                addStar(newCell);
                break;
            case "G":
                newCell.className += " green_tile";
                addStar(newCell);
                break;
            case "B":
                newCell.className += " blue_tile";
                addStar(newCell);
                break;
            }
        });
    });
}

/**
 * Refresh board display
 * @param {JSON} context the context of execution
 * @param {string} boardElementId the id of the board element
 */
function refreshDisplay(context, boardElementId) {
    // Refresh the robot position
    removeRobot();
    addRobot(boardElementId, context.robot);

    
    if (context && context.pc && context.program) {
        if (parseInt(document.querySelector("#stack .list").dataset.function) !== context.pc[0]) {
            // Remove all elements from the stack
            clearCallStack();
            for (let instruction of context.program[context.pc[0]]) {
                addCallStack(instruction.join(" "));
            }
        }
        
        // Refresh the live stack display
        setActiveInstruction(context.pc[0], context.pc[1]);
    }
}

const robotID = "robot";

/**
 * Remove the robot from the board
 */
function removeRobot() {
    const rCell = document.getElementById(robotID);
    rCell.removeAttribute("id");
    rCell.removeAttribute("style");
    rCell.innerText = icons.empty.string;
}

/**
 * Add the robot to the board
 * @param {string} boardElementId the id of the board element
 * @param {JSON} robot the robot to add
 */
function addRobot(boardElementId, robot) {
    let aBoard = document.getElementById(boardElementId);
    let aCell = aBoard.rows[robot.y].cells[robot.x];
    let anIcon = icons[Object.keys(icons)[robot.dir]];
    aCell.id = robotID;
    aCell.innerText = anIcon.string;
    aCell.style.color = anIcon.color;
}

export {
    toggleFullScreen,
    fillBoard,
    refreshDisplay,
    addRobot,
};