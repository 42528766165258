import { checkProgram } from "../../src/interpreter.js";

// Default program limitations
let limitations = [];

// Program errors and warnings
let errors = ["The program is empty"];
let warnings = [];

/**
 * Set program limitations
 * @param {Array<number>} newLimitations the new limitations
 */
function setProgramLimitations(newLimitations) {
    limitations = newLimitations;
}

/**
 * Clear all errors in the viewer interface and close the errors panel
 */
function clearErrors() {
    errors = [];
    const errorsContainer = document.querySelector("#errors .list");
    errorsContainer.innerHTML = "";
    const errorsCount = document.querySelector("#errors .count");
    errorsCount.innerHTML = 0;
    document.querySelector("#errors").removeAttribute("open");
}

/**
 * Display an new error in the viewer interface
 * @param {string} message the error message
 */
function addError(message) {
    errors.push(message);
    const errorsContainer = document.querySelector("#errors .list");
    const error = document.createElement("li");
    error.innerHTML = message;
    const timeIndicator = document.createElement("span");
    timeIndicator.classList.add("time");
    timeIndicator.innerHTML = new Date().toLocaleTimeString();
    error.appendChild(timeIndicator);
    errorsContainer.appendChild(error);
    const errorsCount = document.querySelector("#errors .count");
    errorsCount.innerHTML = errorsContainer.children.length;
    document.querySelector("#errors").setAttribute("open", "true");
    errorsContainer.scrollTop = errorsContainer.scrollHeight;
}

/**
 * Get program errors
 * @returns {Array<string>} the program errors
 */
function getErrors() {
    return errors;
}

/**
 * Clear all warnings in the viewer interface and close the warnings panel
 */
function clearWarnings() {
    warnings = [];
    const warningsContainer = document.querySelector("#warnings .list");
    warningsContainer.innerHTML = "";
    const warningsCount = document.querySelector("#warnings .count");
    warningsCount.innerHTML = 0;
    document.querySelector("#warnings").removeAttribute("open");
}

/**
 * Display an new warning in the viewer interface
 * @param {string} message the warning message
 */
function addWarning(message) {
    warnings.push(message);
    const warningsContainer = document.querySelector("#warnings .list");
    const warning = document.createElement("li");
    warning.innerHTML = message;
    const timeIndicator = document.createElement("span");
    timeIndicator.classList.add("time");
    timeIndicator.innerHTML = new Date().toLocaleTimeString();
    warning.appendChild(timeIndicator);
    warningsContainer.appendChild(warning);
    const warningsCount = document.querySelector("#warnings .count");
    warningsCount.innerHTML = warningsContainer.children.length;
    document.querySelector("#warnings").setAttribute("open", "true");
    warningsContainer.scrollTop = warningsContainer.scrollHeight;
}

/**
 * Clear the call stack in the viewer interface
 */
function clearCallStack() {
    const callStackContainer = document.querySelector("#stack .list");
    callStackContainer.innerHTML = "";
    const callStackCount = document.querySelector("#stack .count");
    callStackCount.innerHTML = 0;
}

/**
 * Display a new instruction in the call stack
 * @param {string} message the instruction message
 */
function addCallStack(message) {
    const callStackContainer = document.querySelector("#stack .list");
    const callStack = document.createElement("li");
    callStack.innerHTML = message;
    callStackContainer.appendChild(callStack);
    const callStackCount = document.querySelector("#stack .count");
    callStackCount.innerHTML = parseInt(callStackCount.innerHTML) + 1;
    document.querySelector("#stack").setAttribute("open", "true");
    callStackContainer.scrollTop = callStackContainer.scrollHeight;
}

/**
 * Set the active instruction in the call stack
 * @param {number} functionIndex the index of the active function
 * @param {number} instructionIdex the index of the instruction to set as active
 */
function setActiveInstruction(functionIndex, instructionIdex) {
    const instructions = document.querySelectorAll("#stack .list li");
    if (instructionIdex >= 0 && instructionIdex < instructions.length) {
        for (let i = 0; i < instructions.length; i++) {
            instructions[i].classList.remove("active");
        }
        instructions[instructionIdex].classList.add("active");
        const callStackCount = document.querySelector("#stack .count");
        callStackCount.innerHTML = "Func. " + (functionIndex+1) + ", " + (instructionIdex + 1) + "/" + instructions.length;
        const callStackContainer = document.querySelector("#stack .list");
        callStackContainer.dataset.function = functionIndex;
        callStackContainer.scrollTop = callStackContainer.scrollHeight / instructions.length * (instructionIdex - 3);
    }
}

/**
 * Handle file selection
 * @param {Event} evt the event triggered by the file selection
 * @param {() => void} callback the callback to call when the file is loaded
 */
function handleFileSelect(evt, callback) {
    const file = evt.target.files[0];
    const reader = new FileReader();

    // Read the imported file
    reader.onload = function (e) {
        if (e.target.result) {
            callback(e.target.result);
        } else {
            alert("Impossible de lire le fichier. Essayez d'utiliser un autre navigateur");
        }
    };

    // Do not execute the function if the fil is empty
    if (file) {
        reader.readAsText(file);
    }
}

/**
 * Open a file dialog to select a file to open
 * @param {() => void} callback the callback to call when the file is loaded
 * @param {string} type the mime type of the file to open
 */
function openTextFile(callback, type = null) {
    // Check if file upload APIs are supported
    if (window.File && window.FileReader && window.FileList && window.Blob) {

        // Create an invisible file upload button
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.style.display = "none";
        if (type) {
            fileInput.accept = type;
        }

        document.body.appendChild(fileInput);

        // Add an event listener and trigger it
        fileInput.addEventListener("change", (event) => {
            handleFileSelect(event, callback);
        }, false);
        fileInput.click();

        // Remove the element
        setTimeout(function () {
            document.body.removeChild(fileInput);
        }, 0);
    } else {
        // Alert the user
        alert("Your browser does not support file upload APIs");
    }
}

/**
 * Trigger the download of the current program as a text file
 * @param {string} text the text to download
 * @param {string} filename the name of the file to download
 * @param {string} type the mime type of the file to download
 */
function saveTextFile(text, filename, type = "text/plain;charset=utf-8") {
    const blob = new Blob([text], {
        type
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.click();
}

/**
 * Function called to check the syntax when the user type in the editor or load a file
 */
function checkSyntax() {
    clearErrors();
    clearWarnings();
    clearCallStack();
    const program = getProgram();

    checkProgram(program, addError, addWarning, limitations);
}

/**
 * Load a program from a string into the editor
 * @param {string} program the source code of the program 
 */
function loadProgram(program) {
    const editor = document.querySelector("#editor-content");
    if (editor.value !== "") {
        const replace = confirm("Are you sure you want to replace the current program?");
        if (replace) {
            editor.value = program;
        }
    } else {
        editor.value = program;
    }

    checkSyntax();
}

/**
 * Get the current program from the editor
 * @returns {string} the source code of the program
 */
function getProgram() {
    const editor = document.querySelector("#editor-content");
    return editor.value;
}


/**
 * Load a program from a file into the editor
 */
function loadProgramFromDisk() {
    openTextFile((text) => {
        loadProgram(text);
    });
}

/**
 * Load a puzzle from a file into the viewer
 * @param {() => void} callback the callback to call when the file is loaded
 */
function loadPuzzleFromDisk(callback) {
    openTextFile((puzzleContent) => {
        try {
            const json = JSON.parse(puzzleContent);
            callback(json);
        } catch (e) {
            alert("Unable to parse the puzzle file");
        }
    }, "application/json");
}

/**
 * Load a program from an URL into the editor
 * (the endpoint must accept CORS or the browser will not be able to load the program)
 */
function loadProgramFromURL() {
    const url = prompt("Enter the URL of the program to load");
    if (url) {
        fetch(url)
            .then(response => response.text())
            .then(text => {
                loadProgram(text);
            })
            .catch((error) => {
                addError(error.message);
            });
    }
}

/**
 * Load a puzzle from an URL into the visualizer
 * (the endpoint must accept CORS or the browser will not be able to load the program)
 * @param {() => void} callback the callback to call when the file is loaded
 */
function loadPuzzleFromURL(callback) {
    const url = prompt("Enter the URL of the puzzle to load");
    if (url) {
        fetch(url)
            .then(response => response.json())
            .then(json => {
                callback(json);
            })
            .catch((error) => {
                addError(error.message);
            });
    }
}

/**
 * Save the content of the editor into a program file
 */
function saveProgram() {
    const editor = document.querySelector("#editor-content");
    saveTextFile(editor.value, "program.txt");
}

/**
 * Generate a URL to share the current program
 */
function shareProgram() {
    const editor = document.querySelector("#editor-content");
    const url = window.location.origin + "/?program=" + encodeURIComponent(editor.value);
    prompt("Copy the following URL to share your program", url);
}

/**
 * Insert a new instruction at the current cursor position
 * @param {string} instruction the instruction to insert
 */
function insertInstruction(instruction) {
    // Get cursor position
    const editor = document.querySelector("#editor-content");
    let cursor = editor.selectionStart;
    const text = editor.value;

    // If current line already has an instruction, insert the new instruction after the current instruction
    if (text[cursor - 1] !== "\n" && cursor > 0) {
        instruction = "\n" + instruction;
    }
    if (text[cursor + 1] !== "\n" && cursor < text.length) {
        instruction += "\n";
    }

    // Insert the instruction
    editor.value = text.substring(0, cursor) + instruction + text.substring(cursor);

    // Set new cursor position
    editor.selectionStart = cursor + instruction.length;
    editor.selectionEnd = cursor + instruction.length;

    // Focus the editor
    editor.focus();

    // Check syntax
    checkSyntax();
}

export {
    setProgramLimitations,
    clearErrors,
    addError,
    getErrors,
    clearWarnings,
    addWarning,
    clearCallStack,
    addCallStack,
    setActiveInstruction,
    checkSyntax,
    loadProgram,
    getProgram,
    loadProgramFromDisk,
    loadPuzzleFromDisk,
    loadProgramFromURL,
    loadPuzzleFromURL,
    saveProgram,
    shareProgram,
    insertInstruction
};