import {findStars} from "./moves.js";

function createNewContext(puzzle, robot, program) {
    return {
        stack: [],
        program,
        puzzle,
        robot,
        pc: [0,0],
        stars: findStars(puzzle.board)
    };
}

export {
    createNewContext
};
