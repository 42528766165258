export function rotateLeft(robot) {
    return robot.dir = (robot.dir + 1) % 4;
}

export function rotateRight(robot) {
    return robot.dir = (robot.dir + 3) % 4;
}

export function move(robot) {
    if (robot.dir % 2 === 1)
        robot.y += robot.dir === 1 ? -1 : 1;
    else
        robot.x += robot.dir === 0 ? 1 : -1;
}

export function isPositionValid(puzzle, robot) {
    return puzzle.board[robot.y][robot.x] !== " " && robot.x >= 0 && robot.y >= 0 && robot.x < puzzle.board[0].length && robot.y < puzzle.board.length;
}

export function isGameWon(context){
    return context.stars.length === 0;
}

export function isOnStar(puzzle, robot) {
    return isStar(puzzle.board[robot.y][robot.x]);
}

function isMapConnected(board, robot, star) {
    let toExplore = board.map((e)=> e = e.split("")).map((e,i)=> e.map((c,j)=> e ={x:j, y:i, checked : false}));
    let width = board[0].length;
    let height = board.length;

    function aux(rbt){
        if (star.x === rbt.x && star.y === rbt.y)
            return true;
        toExplore[rbt.y][rbt.x].checked = true;
        let neighboors = [];
        if(rbt.x !== 0)
            neighboors.push(toExplore[rbt.y][rbt.x-1]);
        if(rbt.y !== 0)
            neighboors.push(toExplore[rbt.y-1][rbt.x]);
        if(rbt.x !== width-1)
            neighboors.push(toExplore[rbt.y][rbt.x+1]);
        if(rbt.y !== height-1)
            neighboors.push(toExplore[rbt.y+1][rbt.x]);
        return neighboors.reduce((acc, e)=> acc || (!e.checked && board[e.y][e.x] !== " " && aux(e)), false);
    }

    return aux(robot);
}

function isStar(letter) {
    return letter === letter.toUpperCase() && letter !== " ";
}
export function findStars(board) {
    let stars = [];
    const width = board[0].length;
    const height = board.length;
    for(let i = 0; i < width ; ++i) {
        for(let j = 0; j < height ; ++j) {
            if(isStar(board[j][i]))
                stars.push({x:i, y:j});
        }
    }
    return stars;
}

// Depth-First Search algorithm : returns an array of coords that form a path from robot to star in board
function findPath(board, robot, star) {
    // track explored tiles
    let toExplore = board.map((e)=> e = e.split("")).map((e,i)=> e.map((c,j)=> e ={x:j, y:i, checked : false}));
    let width = board[0].length;
    let height = board.length;
    let final = []; //will contain the final path from robot to star
    function aux(rbt,path){
        if (star.x === rbt.x && star.y === rbt.y)
            final = path;
    
        path.push({x: rbt.x, y : rbt.y});
        toExplore[rbt.y][rbt.x].checked = true;
        //Check neighboors for walkable and unexplored tiles 
        if(final.length === 0) {
            if(rbt.x !== 0 && board[rbt.y][rbt.x-1] !== " " && !toExplore[rbt.y][rbt.x-1].checked)
                aux({x : rbt.x - 1, y : rbt.y, dir : rbt.dir}, path);
            if(rbt.y !== 0 && board[rbt.y-1][rbt.x] !== " " && !toExplore[rbt.y-1][rbt.x].checked)
                aux({x : rbt.x, y : rbt.y-1, dir : rbt.dir}, path);
            if(rbt.x !== width-1 && board[rbt.y][rbt.x+1] !== " " && !toExplore[rbt.y][rbt.x+1].checked)
                aux({x : rbt.x + 1, y : rbt.y, dir : rbt.dir}, path);
            if(rbt.y !== height-1 && board[rbt.y+1][rbt.x] !== " " && !toExplore[rbt.y+1][rbt.x].checked) 
                aux({x : rbt.x, y : rbt.y+1, dir : rbt.dir}, path);
        }
    }
    aux(robot,[]);
    return final;
}

function getDirection(pos1, pos2) {
    if(pos1.x !== pos2.x) return pos1.x - pos2.x > 0 ? 2 : 0;
    return pos1.y - pos2.y < 0 ? 3 : 1;
}

// PRECOND: Game board is connected ('solvable')
// gives a naive solution set of instructions to lead robot to star
export function makeNaiveSolution(board, robot, star) {
    let path = findPath(board, robot, star);
    let sol = [];
    let rob = {...robot};
    for(let i = 1; i < path.length; ++i) {
        let dir = getDirection(path[i-1], path[i]);
        if(dir === rob.dir) {
            sol.push("move");
            move(rob);
        } else 
        {
            switch(Math.abs(rob.dir - dir)){
            case 2:
                sol.push("turnLeft " + board[path[i-1].y][path[i-1].x].toLowerCase());
                sol.push("turnLeft " + board[path[i-1].y][path[i-1].x].toLowerCase());
                sol.push("move");
                rotateLeft(rob);
                rotateLeft(rob);
                move(rob);
                break;
            default:
                if(rob.dir - dir === -3 || rob.dir - dir === 1){
                    sol.push("turnRight " + board[path[i-1].y][path[i-1].x].toLowerCase());
                    sol.push("move");
                    rotateRight(rob);
                    move(rob);
                } else {
                    sol.push("turnLeft " + board[path[i-1].y][path[i-1].x].toLowerCase());
                    sol.push("move");
                    rotateLeft(rob);
                    move(rob);
                }
                break;
            }
        }
    }
    return { solution : sol, dir : rob.dir } ;
}

export function getCompleteNaiveSolution(context) {
    let final_solution = [];
    let rob = {...context.robot};
    for(let i = 0; i < context.stars.length; ++i) {
        let sol = makeNaiveSolution(context.puzzle.board, rob, context.stars[i]);
        final_solution = final_solution.concat(sol.solution);
        rob = { x : context.stars[i].x, y : context.stars[i].y, dir: sol.dir };
    }
    return final_solution;
}

export function isGameSolvable(context) {
    const stars = findStars(context.puzzle.board);
    let rbt = {x: context.robot.x, y:context.robot.y};
    return stars.length > 0 && stars.reduce((acc,e)=> acc && isMapConnected(context.puzzle.board, rbt, e), true);
}

