import * as editor from "./editor";
import * as viewer from "./viewer";

import { createNewContext } from "../../src/context.js";
import { isGameWon, isPositionValid, getCompleteNaiveSolution } from "../../src/moves.js";
import { step, parseProgram, checkProgram } from "../../src/interpreter.js";

const BOARD_ID = "visualizer_board";

// Default puzzle data
const DEFAULT_PUZZLE = {
    "name": "Right on red",
    "puzzle": {
        "limitations": [6, 5],
        "board": [
            "                ",
            "                ",
            "     gbbbbbbg   ",
            "     b      b   ",
            "     b      b   ",
            "     b bbbbbg   ",
            "     b          ",
            "   B grg        ",
            "   b  b         ",
            "   gbbg         ",
            "                ",
            "                "
        ]
    },
    "robot": {
        "x": 7,
        "y": 5,
        "dir": 0
    }
};

/**
 * Utility function to limit successive calls to a function with a given delay
 * @param {() => void} func the function to call 
 * @param {number} timeout the delay to wait before calling the function
 * @returns 
 */
function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

// When the page is loaded, call the main function
document.addEventListener("DOMContentLoaded", () => {
    // Global variables
    let puzzleData, context;
    let speed = 10;
    let playBackState = {
        "running": false,
        "paused": false,
        "currentStep": 0,
    };

    let buttons = {
        stop: document.querySelector("button.action-stop"),
        play: document.querySelector("button.action-play"),
        pause: document.querySelector("button.action-pause"),
        stepForward: document.querySelector("button.action-step-forward"),
        toggleFullScreen: document.querySelector("#button-toggle-fullscreen"),
    };

    const playback = {
        stop: () => {
            playBackState.running = false;
            playBackState.paused = false;
            playBackState.currentStep = 0;
            buttons.play.classList.remove("hidden");
            buttons.pause.classList.add("hidden");
            console.log("Playback stopped");
        },
        start: () => {
            playBackState.running = true;
            playBackState.paused = false;
            playBackState.currentStep = 0;
            buttons.play.classList.add("hidden");
            buttons.pause.classList.remove("hidden");
            buttons.stepForward.classList.remove("hidden");
            console.log("Start");
        },
        pause: () => {
            playBackState.paused = true;
            buttons.play.classList.remove("hidden");
            buttons.pause.classList.add("hidden");
            console.log("Pause");
        },
        resume: () => {
            playBackState.paused = false;
            buttons.play.classList.add("hidden");
            buttons.pause.classList.remove("hidden");
            console.log("Resume");
        }
    };

    function loadPuzzle(puzzle) {
        puzzleData = {...puzzle};
        // Build the board and add the robot
        viewer.fillBoard(BOARD_ID, puzzleData.puzzle);
        viewer.addRobot(BOARD_ID, puzzleData.robot);
        editor.setProgramLimitations(puzzleData.puzzle.limitations);
        document.getElementById("puzzle-name").innerText = puzzleData.name;
        const description = `Solvable in ${puzzleData.puzzle.limitations.length} function${puzzleData.puzzle.limitations.length > 1 ? "s" : ""} of ${puzzleData.puzzle.limitations.join(", ").replace(/, ([^,]*)$/, " and $1")} instructions`;
        document.getElementById("puzzle-description").innerText = description;
    }

    function initialize() {
        // Check if a puzzle has been loaded
        if (!puzzleData) {
            throw new Error("No puzzle has been loaded");
        }

        // Check if there is a valid program to run
        const program = editor.getProgram();
        const errors = [];
        const warnings = [];
        checkProgram(program, (error) => errors.push(error), (warning) => warnings.push(warning), puzzleData.puzzle.limitations);

        if (errors.length > 0) {
            throw new Error("Program has errors");
        }

        // Create a new context
        context = createNewContext({...puzzleData.puzzle}, {...puzzleData.robot}, parseProgram(program));
    }

    function checkWinStatus() {
        if (isGameWon(context)) {
            alert(`Congratulations, you won in ${playBackState.currentStep} steps !`);
            stop();
        } else if (!isPositionValid(context.puzzle, context.robot)) {
            alert(`Failed ! You fell off the board after ${playBackState.currentStep} steps`);
            stop();
        } else if (context.pc[1] === context.program[context.pc[0]].length) {
            alert(`Failed ! You reached the end of the program after ${playBackState.currentStep} steps but did not collect all the stars`);
            stop();
        } else {
            playBackState.currentStep++;
            viewer.refreshDisplay(context, BOARD_ID);
        }
    }

    async function play() {
        if (playBackState.running && !playBackState.paused) {
            return;
        }

        const programErrors = editor.getErrors();
        if (programErrors.length > 0) {
            alert(`The program contains errors, please fix them before trying to run it : \n - ${programErrors.join("\n - ")}`);
            return;
        }

        if (playBackState.paused) {
            playback.resume();
        } else if (!playBackState.running) {
            initialize();
            playback.start();
        }

        while (step(context) && playBackState.running && !playBackState.paused) {
            playBackState.currentStep++;
            viewer.refreshDisplay(context, BOARD_ID);
            await new Promise(r => setTimeout(r, 1000 / speed));
        }
        checkWinStatus();
    }

    
    function stepForward() {
        const programErrors = editor.getErrors();
        if (programErrors.length > 0) {
            alert(`The program contains errors, please fix them before trying to run it : \n - ${programErrors.join("\n - ")}`);
            return;
        }

        if (!playBackState.running) {
            initialize();
            playback.start();
        }

        if (!playBackState.paused) {
            playback.pause();
        }

        viewer.refreshDisplay(context, BOARD_ID);
        step(context);
        checkWinStatus();
    }
    
    function stop() {
        playback.stop();
        
        // Reload the puzzle
        loadPuzzle(puzzleData);
        
        // Reset the context
        context = createNewContext({...puzzleData.puzzle}, {...puzzleData.robot}, context ? context.program : "");
        
        // Refresh the display
        viewer.refreshDisplay(context, BOARD_ID);
    }

    function generateSolution() {
        stop();
        const programSolution = getCompleteNaiveSolution(context);
        editor.loadProgram(programSolution.join("\n"));
    }

    
    // Load the default puzzle
    loadPuzzle(DEFAULT_PUZZLE);
    
    // Get the program from the URL if there is one
    const urlParams = new URLSearchParams(window.location.search);
    const program = urlParams.get("program");
    if (program) {
        editor.loadProgram(decodeURIComponent(program));
    }

    // Add event listeners
    document.querySelector("#editor-content").addEventListener("input", debounce(() => {
        editor.checkSyntax();

        // Stop the playback
        playback.stop();
    }));

    document.querySelectorAll(".action-play").forEach(e => e.addEventListener("click", play));
    document.querySelectorAll(".action-step-forward").forEach(e => e.addEventListener("click", stepForward));
    document.querySelectorAll(".action-stop").forEach(e => e.addEventListener("click", stop));

    document.querySelector("#link-load-program-from-disk").addEventListener("click", editor.loadProgramFromDisk);
    document.querySelector("#link-load-program-from-url").addEventListener("click", editor.loadProgramFromURL);
    document.querySelector("#link-save-program").addEventListener("click", editor.saveProgram);
    document.querySelector("#link-share-program").addEventListener("click", editor.shareProgram);
    
    document.querySelector("#link-load-puzzle-from-disk").addEventListener("click", () => editor.loadPuzzleFromDisk(loadPuzzle));
    document.querySelector("#link-load-puzzle-from-url").addEventListener("click", () => editor.loadPuzzleFromURL(loadPuzzle));
    
    document.querySelectorAll("[data-instruction]").forEach(e => {
        e.addEventListener("click", (event) => {
            editor.insertInstruction(event.target.dataset.instruction);
        });
    });

    document.querySelector("#warnings .clear").addEventListener("click", editor.clearWarnings);
    document.querySelector("#errors .clear").addEventListener("click", editor.clearErrors);

    document.querySelectorAll(".action-pause").forEach(e => e.addEventListener("click", playback.pause));
    
    document.querySelector("#speed-slider").addEventListener("input", (event) => {
        speed = event.target.value;
        document.querySelector("#speed-value").innerText = `Speed : ${speed} instruction${speed > 1 ? "s" : ""} per second`;
    });

    buttons.toggleFullScreen.addEventListener("click", viewer.toggleFullScreen);

    document.querySelector(".generate-solution").addEventListener("click", generateSolution);
});